/* eslint-disable import/extensions */

// Services Img Imports
import Web from '../assets/images/Services/Web.png';
import Mobile from '../assets/images/Services/Mobile.png';
import UIUX from '../assets/images/Services/Design.png';
import Ai from '../assets/images/Services/Ai.png';

// Portfolio Img Imports
import Recruiting from '../assets/images/Portfolio/Recruiting.png';
import Stream from '../assets/images/Portfolio/Stream.png';
import Freelance from '../assets/images/Portfolio/Freelance.png';
import Aura from '../assets/images/Portfolio/Aura.png';
import Surtido from '../assets/images/Portfolio/Surtido.png';
import ManagementApp from '../assets/images/Portfolio/ManagementApp.png';

// Advantages
import Communicative from '../assets/images/Advantages/Communicative.png';
import Collaborative from '../assets/images/Advantages/Collaborative.png';
import Management from '../assets/images/Advantages/Management.png';
import Favorite from '../assets/images/Advantages/Favorite.png';

// Testimonials
import Avatar1 from '../assets/images/Testimonials/avatar_1.png';
import Avatar2 from '../assets/images/Testimonials/avatar_2.png';
import Avatar3 from '../assets/images/Testimonials/avatar_3.png';
import Avatar4 from '../assets/images/Testimonials/avatar_4.png';

// TeamMembers
import CEO from '../assets/images/TeamMembers/CEO.jpg';
import HRD from '../assets/images/TeamMembers/HRD.jpg';
import Finance from '../assets/images/TeamMembers/Finance.png';
import ProjectManager from '../assets/images/TeamMembers/PM.png';
import Frontend1 from '../assets/images/TeamMembers/Frontend1.png';
import Frontend2 from '../assets/images/TeamMembers/Frontend2.png';
import Backend1 from '../assets/images/TeamMembers/Backend1.jpg';
import Backend2 from '../assets/images/TeamMembers/Backend2.png';
import Mobile1 from '../assets/images/TeamMembers/Mobile1.png';
import Mobile2 from '../assets/images/TeamMembers/Mobile2.png';
import Mobile3 from '../assets/images/TeamMembers/Mobile3.png';
import UIUX1 from '../assets/images/TeamMembers/UIUX1.png';
import UIUX2 from '../assets/images/TeamMembers/UIUX2.png';

//  Ana sayfa sundugumuz hizmetler 

export const Services = [
  {
    title: 'Web Development',
    imageUrl: Web,
    animation: 'left',
  },
  {
    title: 'Mobile Development',
    imageUrl: Mobile,
    animation: 'up',
  },
  {
    title: 'UI/UX Design',
    imageUrl: UIUX,
    animation: 'right',
  },
  {
    title: 'AI Application',
    imageUrl: Ai,
    animation: 'left',
  },
];
//  Portfilo page projelerimiz
export const Portfolios = [
  {
    id: 'asd1293uasdads1',
    title: 'Recruiting App',
    imageUrl: Recruiting,
    type: 'Mobile Apps',
    responsibility: [
      'Mobile Development',
      'UI/UX Design',
    ],
    credit: 'https://dribbble.com/shots/15164950-Recruiting-app',
  },
  {
    id: 'asd1293uhjkhkjh2',
    title: 'Stream+',
    imageUrl: Stream,
    type: 'Mobile Apps',
    responsibility: [
      'Mobile Development',
      'UI/UX Design',
    ],
    credit: 'https://dribbble.com/shots/15276430-Stream',
  },
  {
    id: 'asd1293uvbvcbbd3',
    title: 'Freelance',
    imageUrl: Freelance,
    type: 'Mobile Apps',
    responsibility: [
      'Mobile Development',
      'UI/UX Design',
    ],
    credit: 'https://dribbble.com/shots/15223131-Freelance-Mobile-App-Concept',
  },
  {
    id: 'asd1293ufgdfgs4',
    title: 'Aura',
    imageUrl: Aura,
    type: 'Website',
    responsibility: [
      'Web Development',
      'UI/UX Design',
    ],
    credit: 'https://dribbble.com/shots/15176338-Aura-Website-Main-Page',
  },
  {
    id: 'asd1293ulskmnb5',
    title: 'Surtido Rico',
    imageUrl: Surtido,
    type: 'Website',
    responsibility: [
      'Web Development',
      'UI/UX Design',
    ],
    credit: 'https://dribbble.com/shots/15173118-Surtido-Rico',
  },
  {
    id: 'asd1293ulkmnbj6',
    title: 'Courses Management',
    imageUrl: ManagementApp,
    type: 'Website',
    responsibility: [
      'Web Development',
      'UI/UX Design',
    ],
    credit: 'https://dribbble.com/shots/15197890-Courses-Management-and-Promoting-Web-App',
  },
  {
    id: 'asd1293ulkmnbasd6',
    title: 'Courses Management',
    imageUrl: ManagementApp,
    type: 'AI Project',
    responsibility: [
      'Web Development',
      'AI Project',
    ],
    credit: 'https://dribbble.com/shots/15197890-Courses-Management-and-Promoting-Web-App',
  },
];
//  Ana sayfa sirket vizyonumuz
export const Advantages = [
  [{
    title: 'Communicative',
    description: 'We communicate our project ideas and progress to make it clear.',
    imageUrl: Communicative,
  },
  {
    title: 'Management',
    description: 'We manage our project properly to make our project done well.',
    imageUrl: Management,
  }],
  [{
    title: 'Collaborative​',
    description: 'Our team are very collaborative to make our project done well.',
    imageUrl: Collaborative,
  },
  {
    title: 'Favorite',
    description: "We've did so many project and all of our client love it.",
    imageUrl: Favorite,
  }],
];
//  Ana sayfa Proje yaptiran kisiler
export const Testimonials = [
  {
    id: 1,
    name: 'Emma Brown',
    company: 'Owner, SudokuAI',
    testimoni: 'Thanks for SudokuAI, you guys are the best! It was a pleasure to work with your team.',
    imageUrl: Avatar1,
  },
  {
    id: 2,
    name: 'Merve Demir',
    company: 'Director, Shopping Management',
    testimoni: 'I want you to know that AimazingApps was great to work with, they did a great job in a short time.',
    imageUrl: Avatar2,
  },
  {
    id: 3,
    name: 'John Miller',
    company: 'Environmental Engineer, Test Water',
    testimoni: 'They realised our beautiful ideas about natural life at an affordable price. Thank you for giving us these opportunities.',
    imageUrl: Avatar3,
  },
  {
    id: 4,
    name: 'Emre Şahin',
    company: 'Owner, Pico Game',
    testimoni: 'They delivered our project in a short time. I look forward to working on other projects again.',
    imageUrl: Avatar4,
  },
];

export const TeamMembers = [
  {
    name: 'Enes Pecenek',
    position: 'CEO',
    imageUrl: CEO,
  },
  {
    name: 'Eda Gökpınar',
    position: 'HRD',
    imageUrl: HRD,
  },
  {
    name: 'Melisa Atak',
    position: 'Finance',
    imageUrl: Finance,
  },
  {
    name: 'İbrahim Akpınar',
    position: 'Project Manager',
    imageUrl: ProjectManager,
  },
  {
    name: 'Okan Tuna',
    position: 'Front-end Developer',
    imageUrl: Frontend1,
  },
  {
    name: 'Nuri Ceylan',
    position: 'Front-end Developer',
    imageUrl: Frontend2,
  },
  {
    name: 'Selim Ünlü',
    position: 'Back-end Developer',
    imageUrl: Backend1,
  },
  {
    name: 'Engin Yurttaş',
    position: 'Back-end Developer',
    imageUrl: Backend2,
  },
  {
    name: 'Burak Karadere',
    position: 'Mobile Developer',
    imageUrl: Mobile1,
  },
  {
    name: 'Ferhat Yaylalı',
    position: 'Mobile Developer',
    imageUrl: Mobile2,
  },
  {
    name: 'Derin Öncü',
    position: 'Mobile Developer',
    imageUrl: Mobile3,
  },
  {
    name: 'Ahmet Turan',
    position: 'UI/UX Designer',
    imageUrl: UIUX2,
  },
  {
    name: 'Ayşe Deniz',
    position: 'UI/UX Designer',
    imageUrl: UIUX1,
  },
];
